import { ButtonSet } from '@components/ButtonSet';
import { RolesProperties } from '@core/@models/AttentionModel';
import { DcaConfigFormProps } from '@core/@models/DcaConfigModel';
import {
  ManageRolesProfileRequest,
  ManageRolesProfileResponse,
} from '@core/@models/ManageRoleSupport';
import { ApiService } from '@core/services/api.service';
import { CustomService } from '@core/services/custom.service';
import { Checkbox, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { finalize } from 'rxjs/operators';
const { Item } = Form;
const layout = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 14,
  },
};
export const ManageRoleSupportForm: React.FC<DcaConfigFormProps> = ({
  id,
  handleCancel,
  forceFetchData,
}) => {
  const { t } = useTranslation(['common']);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roleOptions, setRoleOptions] = useState<
    { label: string; value: number }[]
  >();
  const rolesRef = useRef<RolesProperties[]>();
  const apiService = useMemo(
    () => new ApiService('/manage-role-support/regulator-profile'),
    []
  );
  useEffect(() => {
    getForm();
  }, []);

  const getForm = () => {
    if (!id) return;
    setLoading(true);
    CustomService.getData<ManageRolesProfileResponse>(
      `/manage-role-support/regulator-profile/${id}`
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: tranformData,
      });
  };

  const tranformData = ({
    profile,
    regulatorRoles,
  }: ManageRolesProfileResponse) => {
    const regulatorRoleIds = profile.regulatorRoles.map((v) => v.id);
    const mapData = Object.assign({}, profile, {
      regulatorRoles: regulatorRoleIds,
    });

    const roles = [...profile.regulatorRoles, ...regulatorRoles].sort(
      (a: RolesProperties, b: RolesProperties) => a.id - b.id
    );
    rolesRef.current = roles;
    const result = roles.map(({ name, id, descriptionTh }) => ({
      label: `${name} - ${descriptionTh} `,
      value: id,
    }));
    setRoleOptions(result);
    form.setFieldsValue(mapData);
  };

  const onFinish = (formData: ManageRolesProfileRequest) => {
    const regulatorRoles = rolesRef.current?.filter((role) =>
      formData.regulatorRoles.includes(role.id)
    );
    const requstData = { ...formData, regulatorRoles, id };
    setLoading(true);
    apiService
      .updateData(requstData)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          forceFetchData();
          handleCancel();
        },
      });
  };

  return (
    <Form
      {...layout}
      data-testid="manage-role-suport-form"
      onFinish={onFinish}
      form={form}
    >
      <Item name="login" label={t('loginName')}>
        <Input placeholder={t('loginName')} disabled />
      </Item>
      <Item name="name" label={t('name')}>
        <Input placeholder={t('name')} disabled />
      </Item>
      <Item name="surname" label={t('surname')}>
        <Input placeholder={t('surname')} disabled />
      </Item>
      <Item name="email" label={t('email')}>
        <Input placeholder={t('email')} disabled />
      </Item>

      <Item name="regulatorRoles" label={t('role')} wrapperCol={{ span: 10 }}>
        <Checkbox.Group options={roleOptions} />
      </Item>
      <Item
        name="remark"
        label={t('remark')}
        rules={[{ required: true }, { max: 100 }]}
      >
        <TextArea
          data-testid="remark"
          showCount
          maxLength={100}
          placeholder={t('remark')}
        />
      </Item>
      <ButtonSet handleCancel={handleCancel} loading={loading}></ButtonSet>
    </Form>
  );
};
