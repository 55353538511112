import { ISiderMenu } from '@contexts/AuthProvider';
import { withCheckAccessTime } from '@helpers/with-access-time';
import React, { FunctionComponent } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface CheckAccessTimeRouteProps extends Omit<RouteProps, 'component'> {
  component: FunctionComponent;
}
export interface ICheckAccessTime {
  menuId: string;
  subMenuId: string;
  siderMenus: ISiderMenu | undefined;
}
const CheckAccessTimeRoute: React.FC<CheckAccessTimeRouteProps> = ({
  component: Component,
  ...props
}) => {
  return <Route component={withCheckAccessTime(Component)} {...props} />;
};

export default CheckAccessTimeRoute;
